// src/App.js

import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, message } from 'antd';
//import 'antd/dist/antd.css'; // Assurez-vous que les styles Ant Design sont importés
import moment from 'moment';
import 'moment/locale/fr'; // Importer la locale française
import axios from 'axios';

// Configurer Moment.js pour utiliser la locale française
moment.locale('fr');

function App() {
  // État pour savoir si l'utilisateur est en train de travailler
  const [isWorking, setIsWorking] = useState(false);
  
  // État pour stocker les périodes de travail
  const [workPeriods, setWorkPeriods] = useState([]);
  
  // État pour stocker le temps écoulé pendant le travail en cours
  const [elapsedTime, setElapsedTime] = useState('');
  
  // État pour gérer l'état de sauvegarde (loading)
  const [isSaving, setIsSaving] = useState(false);
  
  // Ref pour stocker l'ID de l'intervalle
  const intervalRef = useRef(null);
  
  // Charger les données depuis le serveur au montage du composant
  useEffect(() => {
    fetchWorkPeriods();
    
    // Nettoyer l'intervalle lors du démontage du composant
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  // Fonction pour récupérer les périodes de travail depuis le serveur
  const fetchWorkPeriods = () => {
    axios.get('https://api.vundewaert.be/work-periods')
      .then(response => {
        let data = response.data.data;
        // Trier les données de la plus récente à la plus ancienne
        data.sort((a, b) => moment(b.start).diff(moment(a.start)));
        setWorkPeriods(data);
        // Vérifier si une période est en cours
        const ongoingPeriod = data.find(period => !period.end);
        if (ongoingPeriod) {
          setIsWorking(true);
          startElapsedTime(ongoingPeriod.start);
        }
      })
      .catch(err => {
        console.error('Erreur lors de la récupération des périodes de travail:', err);
        message.error('Erreur lors de la récupération des périodes de travail.');
      });
  };

  // Fonction pour démarrer le chronomètre
  const startElapsedTime = (startTime) => {
    if (intervalRef.current) return; // Empêcher plusieurs intervalles
    intervalRef.current = setInterval(() => {
      const duration = moment.duration(moment().diff(moment(startTime)));
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      setElapsedTime(`${hours}h ${minutes}m ${seconds}s`);
    }, 1000);
  };

  // Fonction pour arrêter le chronomètre
  const stopElapsedTime = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setElapsedTime('');
  };

  // Fonction pour démarrer le travail
  const startWorking = () => {
    setIsSaving(true);
    const startTime = moment().format();
    axios.post('https://api.vundewaert.be/work-periods', { start: startTime, end: null })
      .then(response => {
        const newPeriod = { id: response.data.id, start: startTime, end: null };
        setWorkPeriods([newPeriod, ...workPeriods]); // Ajouter au début
        setIsWorking(true);
        startElapsedTime(startTime);
        message.success('Début du travail enregistré à ' + moment(startTime).format('LLLL'));
      })
      .catch(err => {
        console.error('Erreur lors de l\'enregistrement du début du travail:', err);
        message.error('Erreur lors de l\'enregistrement du début du travail.');
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  // Fonction pour arrêter le travail
  const stopWorking = () => {
    setIsSaving(true);
    const endTime = moment().format();
    const currentPeriod = workPeriods.find(period => !period.end);

    if (currentPeriod) {
      axios.put(`https://api.vundewaert.be/work-periods/${currentPeriod.id}`, { end: endTime })
        .then(() => {
          const updatedPeriods = workPeriods.map(period =>
            period.id === currentPeriod.id ? { ...period, end: endTime } : period
          );
          setWorkPeriods(updatedPeriods);
          setIsWorking(false);
          stopElapsedTime();
          message.success('Fin du travail enregistrée à ' + moment(endTime).format('LLLL'));
        })
        .catch(err => {
          console.error('Erreur lors de la mise à jour de la fin du travail:', err);
          message.error('Erreur lors de la mise à jour de la fin du travail.');
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      setIsSaving(false);
      message.error('Aucune période de travail en cours.');
    }
  };

  // Colonnes pour le tableau des statistiques
  const columns = [
    {
      title: 'Début',
      dataIndex: 'start',
      key: 'start',
      render: (text) => moment(text).format('LLLL'),
    },
    {
      title: 'Fin',
      dataIndex: 'end',
      key: 'end',
      render: (text) => (text ? moment(text).format('LLLL') : 'En cours'),
    },
    {
      title: 'Durée',
      key: 'duration',
      render: (_, record) => {
        if (record.end) {
          const duration = moment.duration(moment(record.end).diff(moment(record.start)));
          const hours = Math.floor(duration.asHours());
          const minutes = duration.minutes();
          const seconds = duration.seconds();
          return `${hours}h ${minutes}m ${seconds}s`;
        }
        return 'En cours';
      },
    },
  ];

  // Calculer le temps total
  const calculateTotalDuration = () => {
    let totalDuration = moment.duration(0);
    workPeriods.forEach(period => {
      if (period.end) {
        totalDuration.add(moment.duration(moment(period.end).diff(moment(period.start))));
      }
    });
    const totalHours = Math.floor(totalDuration.asHours());
    const totalMinutes = totalDuration.minutes();
    const totalSeconds = totalDuration.seconds();
    return `${totalHours}h ${totalMinutes}m ${totalSeconds}s`;
  };

  return (
    <div style={{ padding: 50, textAlign: 'center' }}>
      <h1>Suivi de Travail</h1>

      {/* Bouton Rond en Haut au Centre */}
      <Button
        type="default"
        onClick={isWorking ? stopWorking : startWorking}
        size="large"
        shape="circle"
        style={{
          fontSize: 20,
          width: 120,
          height: 120,
          margin: '20px auto',
          backgroundColor: isSaving ? '#d3d3d3' : isWorking ? '#ff4d4f' : '#1890ff',
          color: '#fff',
        }}
        loading={isSaving}
      >
        {isWorking ? (elapsedTime || 'En cours') : 'Commencer'}
      </Button>

      {/* Affichage du Temps Total */}
      <h2>Total du temps travaillé : {calculateTotalDuration()}</h2>

      {/* Tableau des Périodes de Travail */}
      <Table
        dataSource={workPeriods}
        columns={columns}
        pagination={false}
        rowKey="id"
        style={{ marginTop: 20 }}
        summary={() => {
          const totalDuration = calculateTotalDuration();
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2} style={{ textAlign: 'right' }}>
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{totalDuration}</strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </div>
  );
}

export default App; 